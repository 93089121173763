import React, { useState } from "react";
import { compose } from "recompose";
import classnames from "classnames";
import { defineMessages, FormattedMessage } from "react-intl";

import withConsentCookies from "web/core/analytics/withConsentCookies";
import Button from "theme/components/atoms/Button";
import Spinner from "front-commerce/src/web/theme/components/atoms/Spinner";

const messages = defineMessages({
  serviceDisabled: {
    id: 'modules.Cookie.CookieEnableWrapped.enable',
    defaultMessage: '{serviceName} is disabled.',
  },
  enable: {
    id: 'generic.action.enable',
    defaultMessage: 'Enable',
  },
})

const CookieEnableWrapper = ({
  cookieServiceName,
  children,
// Properties from withConsentCookies.
  cookie = [],
  updateAuthorizationsCookie,
  services,
}) => {
  // To display a spinner to the user whils the browser is reloading.
  const [isLoading, loading] = useState(false);

  // If service do not existe, we exit.
  if (!Object.keys(cookie).includes(cookieServiceName)) {
    return null;
  }
  // Find the service category ( api / ads ... )
  const categorySelected = services.find(({ services }) => {
    return services.find(({name}) => {
      return name === cookieServiceName;
    });
  });
  // Find the service.
  const service = categorySelected.services.find(({name}) => name === cookieServiceName);
  const { title: serviceName } = service;

  const className = classnames('cookie-service-wrapper', {
    [`cookie-service-wrapper--${cookieServiceName}`]: true,
  })

  // Handle service activation.
  const handleEnableService = () => {
    loading(true);
    // Update Authorizations.
    updateAuthorizationsCookie(service, true);
    // To make it simple, we reload the browser.
    if (!process.env.SERVER) {
      window.location.reload();
    }
  }

  // If service is enable we show the child components.
  if (!isLoading && cookie[`${cookieServiceName}`]) {
    return children;
  }

  return (
    <div className={className}>
      {
        !isLoading ?
          <>
            <FormattedMessage
              {...messages.serviceDisabled}
              values={{
                serviceName
              }}
            />
            <Button
              appearance={"primary"}
              onClick={() => {
                handleEnableService();
              }}
            >
              <FormattedMessage {...messages.enable} />
            </Button>
          </>
          : <Spinner />
      }
    </div>
  );
}

export default compose(
  withConsentCookies(),
)(CookieEnableWrapper);
