import React from "react";
import CookieEnableWrapper from "theme/modules/Cookie/CookieEnableWrapper/";
import EnhanceOlapic from "./EnhanceOlapic";
import OlapicConfigQuery from "./OlapicConfigQuery.gql";
import Anchor from "front-commerce/src/web/theme/components/atoms/Typography/Link/Anchor";

/**
 * Since all olapic script is managed by Cookie Management script are added
 * and set by src/web/analytics/olapic.js
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Olapic = ({
  loading,
  olapicEnabled,
}) => {

  if (loading || !olapicEnabled) {
    return null;
  }

  return (
    <CookieEnableWrapper cookieServiceName={"olapic"}>
      <Anchor anchor="inspire" title="Olapic"/>
      <div className="olapic">
        <div id="olapic_specific_widget" className="olapic-with-tac"/>
      </div>
    </CookieEnableWrapper>
  );
};

export default EnhanceOlapic({ OlapicConfigQuery })(Olapic);
